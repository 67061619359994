/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/dynamic-class.scss";
@import "./app/_scrollbar/scrollbar.scss";
@import "./feather.css";

.back-icon {
	z-index: 1000;
}

[dir="rtl"] .back-icon {
	transform: rotate(180deg);
}

.pointer {
	cursor: pointer !important;
}

.width-100 {
	width: 100%
}

.width-fit-content {
	width: fit-content;
}

.mw-70 {
	min-width: 4.375rem;
}

.mw-90 {
	min-width: 5.625rem;
}

.mw-100 {
	min-width: 100px;
}

.mw-120 {
	min-width: 7.5rem;
}

.mw-140 {
	min-width: 140px;
}

.mw-150 {
	min-width: 150px;
}

.mw-160 {
	min-width: 160px;
}

.mw-170 {
	min-width: 170px;
}

.mw-180 {
	min-width: 180px;
}

.mw-200 {
	min-width: 200px;
}

.mw-250 {
	min-width: 250px;
}

.mw-310 {
	min-width: 310px;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.in-english {
	display: block !important;
}

.in-arabic {
	display: none !important;
}


.btn-green {
	background: var(--green-btn-bg);
	color: var(--ion-app-green);
}

.btn-black {
	background: var(--black-bg-btn);
	color: var(--ion-black-primary);
}

.btn-red {
	background: var(--red-btn-bg);
	color: var(--ion-orange-primary);
}

.btn-blue {
	background: var(--blue-btn-bg);
	color: var(--ion-app-blue);
}

.btn-grey {
	background: var(--grey-btn-bg);
	color: var(--ion-black-primary);
}

.btn-dark-blue {
	background: var(--dark-blue-bg-btn);
	color: var(--ion-color-primary-contrast);
}

.btn-orange {
	background: var(--orange-btn-bg);
	color: var(--orange-btn-text);
}

.rotate-180 {
	transform: rotate(180deg) !important;
}


[dir="rtl"] {
	.info-dropdown {
		margin-left: 70px;
		margin-right: unset !important
	}
}

.wpwl-form-card {
	border-radius: 10px;
	background-image: url('./assets/images/BG blue.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
	padding: 20px 20px 40px 20px;
	border-radius: 17px;

	@media screen and (max-width: 768px) {
		background-position: 95% center;
		padding: 10px 20px 20px 20px;
	}
}

.wpwl-form-card {
	@media screen and (max-width: 768px) {
		margin: 0 1rem;
	}
}

.wpwl-button-pay:hover,
.wpwl-button-pay:focus,
.wpwl-button-pay:active,
.wpwl-button-pay {
	background-color: var(--ion-app-primary);
	border-color: var(--ion-app-primary);
}

.wpwl-button-pay[disabled],
.wpwl-button-pay[disabled]:hover,
.wpwl-button-pay[disabled]:focus,
.wpwl-button-pay[disabled]:active {
	background-color: var(--ion-app-primary);
	border-color: var(--ion-app-primary);
}

.wpwl-control,
.wpwl-control-cardNumber,
.wpwl-control-cvv {
	background-color: transparent !important;
	background: transparent !important;
	color: rgb(179, 179, 179) !important;
	border-top-width: 0;
	border-left-width: 0;
	border-right-width: 0;
	border-radius: 0;
	border-color: rgba(255, 255, 255, 0.5);
}

.wpwl-group,
.wpwl-label-brand,
.wpwl-wrapper-brand,
.wpwl-wrapper-registration {
	color: white !important;
	background: transparent !important;

}

.wpwl-control-brand>option,
.wpwl-control-brand>option:hover {
	color: black;
}

.wpwl-clearfix:before,
.wpwl-clearfix:after {
	background: transparent !important;
}

.wpwl-wrapper,
.wpwl-wrapper-cardNumber,
.wpwl-wrapper-cvv,
.wpwl-wrapper-expiry,
.wpwl-control-expiry,
.wpwl-has-error {
	background: transparent !important;
}

.wpwl-button-pay {
	float: left;
	margin: 0;
	border-radius: 1px;
}

.wpwl-group {
	margin: 20px 0 0 0;
}

.wpwl-label {
	margin-bottom: 5px;
}

.textCapitilize {
	text-transform: lowercase;

	&::first-letter {
		text-transform: uppercase;
	}
}

.min-height-content {
	min-height: calc(100vh - 457px);

	@media screen and (max-width: 768px) {
		min-height: unset;
	}
}

.align-end {
	display: flex;
	justify-content: flex-end;
}

.app-text-capitize {
	text-transform: lowercase;

	&::first-letter {
		text-transform: uppercase;
	}
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
	display: contents;
}


.header-background {
	backdrop-filter: none;
}

.cursor-disable {
	cursor: not-allowed !important;
}

sup {
	top: -0.2em;
}

.wrap-slider {
	.swiper-container {
		.swiper-wrapper {
			flex-wrap: wrap !important;
		}
	}
}

.disable {
	opacity: 0.5;
}

.header-md,
.header-ios,
.footer-md,
.footer-ios {
	box-shadow: none;
}