// $dimensions : (0:0px, 3:3px, 5:5px, 7:7px, 8:8px, 10:10px, 12:12px, 15:15px, 16:16px, 20:20px, 24:24px, 25:25px, 30:30px, 32:32px, 35:35px, 40:40px, 45:45px, 50:50px, 55:55px, 60:60px, 65:65px, 70:70px, 75:75px, 95:95px,
//     100:100px, 115:115px, 120:120px, 170:170px, m20:-20px, 1p:1%, 3p:3%, 6p:6%, 7p:7%);

$dimensions : (0:0, 3:0.1875rem, 5:0.3125rem, 7:0.4375rem, 8:0.5rem, 10:0.625rem, 12:0.75rem, 14:0.875rem, 15:0.9375rem, 16:1rem,
	20:1.25rem, 24:1.5rem, 25:1.5625rem, 30:1.875rem, 32:2rem, 35:2.1875rem, 40:2.5rem, 45:2.8125rem, 50:3.125rem,
	55:3.4375rem, 60:3.75rem, 65:4.0625rem, 70:4.375rem, 75:4.6875rem, 80:5rem, 95:5.9375rem, m50:-3.125rem, m12:0.75rem,
	100:6.25rem, 115:7.1875rem, 120:7.5rem, 170:10.625rem, m20:-1.25rem, 1p:1%, 3p:3%, 6p:6%, 7p:7%, auto:auto);

@each $key,
$value in $dimensions {
	@media screen and (max-width: 768px) {
		.mob-m-#{$key} {
			margin: $value !important;
		}

		.mob-mv-#{$key} {
			margin-top: $value !important;
			margin-bottom: $value !important;
		}

		.mob-mh-#{$key} {
			margin-left: $value !important;
			margin-right: $value !important;
		}

		.mob-ml-#{$key} {
			margin-left: $value !important;
			margin-right: 0 !important;
		}

		.mob-mr-#{$key} {
			margin-right: $value !important;
			margin-left: 0 !important;
		}

		.mob-mt-#{$key} {
			margin-top: $value !important;
		}

		.mob-mb-#{$key} {
			margin-bottom: $value !important;
		}

		.mob-p-#{$key} {
			padding: $value !important;
		}

		.mob-pv-#{$key} {
			padding-top: $value !important;
			padding-bottom: $value !important;
		}

		.mob-ph-#{$key} {
			padding-left: $value !important;
			padding-right: $value !important;
		}

		.mob-pl-#{$key} {
			padding-left: $value !important;
			padding-right: 0 !important;
		}

		.mob-pr-#{$key} {
			padding-right: $value !important;
			padding-left: 0 !important;
		}

		.mob-pt-#{$key} {
			padding-top: $value !important;
		}

		.mob-pb-#{$key} {
			padding-bottom: $value !important;
		}


		[dir="rtl"] .mob-ml-#{$key} {
			margin-right: $value !important;
			margin-left: 0 !important;
		}

		[dir="rtl"] .mob-mr-#{$key} {
			margin-left: $value !important;
			margin-right: 0 !important;
		}

		[dir="rtl"] .mob-pl-#{$key} {
			padding-right: $value !important;
			padding-left: 0 !important;
		}

		[dir="rtl"] .mob-pr-#{$key} {
			padding-left: $value !important;
			padding-right: 0 !important;
		}
	}

	.m-#{$key} {
		margin: $value;
	}

	.mv-#{$key} {
		margin-top: $value;
		margin-bottom: $value;
	}

	.mh-#{$key} {
		margin-left: $value;
		margin-right: $value;
	}

	.ml-#{$key} {
		margin-left: $value;
		margin-right: 0;
	}

	.mr-#{$key} {
		margin-right: $value;
		margin-left: 0;
	}

	.mlo-#{$key} {
		margin-left: $value;
	}

	.mro-#{$key} {
		margin-right: $value;
	}

	.mt-#{$key} {
		margin-top: $value;
	}

	.mb-#{$key} {
		margin-bottom: $value;
	}

	.p-#{$key} {
		padding: $value;
	}

	.pv-#{$key} {
		padding-top: $value;
		padding-bottom: $value;
	}

	.ph-#{$key} {
		padding-left: $value !important;
		padding-right: $value !important;
	}

	.web-ph-#{$key} {
		padding-left: $value;
		padding-right: $value;
	}

	.pl-#{$key} {
		padding-left: $value;
		padding-right: 0;
	}

	.pr-#{$key} {
		padding-right: $value;
		padding-left: 0;
	}

	.pt-#{$key} {
		padding-top: $value;
	}

	.pb-#{$key} {
		padding-bottom: $value;
	}


	[dir="rtl"] .ml-#{$key} {
		margin-right: $value;
		margin-left: 0;
	}

	[dir="rtl"] .mr-#{$key} {
		margin-left: $value;
		margin-right: 0;
	}

	[dir="rtl"] .mlo-#{$key} {
		margin-right: $value;
	}

	[dir="rtl"] .mro-#{$key} {
		margin-left: $value;
	}

	[dir="rtl"] .pl-#{$key} {
		padding-right: $value;
		padding-left: 0;
	}

	[dir="rtl"] .pr-#{$key} {
		padding-left: $value;
		padding-right: 0;
	}

	.b-#{$key} {
		border: $value !important;
	}

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 14px !important;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 14px !important;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 16px !important;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 12px !important;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 14px !important;
	}
}


$valueArray : (0:0, 20:20px, 30:30px, 40:40px, 50:50px, 60:60px, 70:70px, 80:80px, 90:90px,
	200:200px, 300:300px, 400:400px, 500:500px, auto:auto, full:100%);

@each $key,
$value in $valueArray {
	.width-#{$key} {
		width: $value ;
	}

	.height-#{$key} {
		height: $value ;
	}
}

.float-right {
	float: right;
}

[dir="rtl"] .float-right {
	float: left;
}